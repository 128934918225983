<template>

  <div class="login-container">
    <b-row>
      <b-col md="12">
        <div class="basic-1 title-1-bold-24 d-flex"
             style="justify-content: center; align-items: center; margin-bottom: 40px">
          {{ $t("AUTH.RESET_PASSWORD") }}
        </div>
      </b-col>
      <b-col md="6" class="mb-5 first-col">
        <div class="basic-1" style="font-size: 14px;">
          {{ $t("AUTH.RESET_PASSWORD_INFO") }}
        </div>
      </b-col>
      <b-col md="6" class="mb-5">
        <form class="form sh-auth-form" novalidate="novalidate" id="sh_forgot_form">
          <div class="form-floating mb-3">
            <input type="text"
                   class="form-control"
                   v-model="model[fields.email.name]"
                   :id="fields.email.name"
                   :placeholder="fields.email.label">
            <label for="floatingInput">{{ fields.email.label }}</label>
          </div>
          <base-button block
                       :callback="doSubmit"
                       :variant="'secondary'"
                       :callbackParams="1">{{ $t('AUTH.SEND_INSTRUCTIONS') }}
          </base-button>
        </form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import UserModel from '@/modules/auth/user-model';
import { server } from '@/const';

const { fields } = UserModel;

export default {
  name: 'ForgotPassword',
  data() {
    return {
      fields,
      model: {},
    };
  },
  methods: {
    ...mapActions({
      doSendPasswordResetEmail: 'auth/doSendPasswordResetEmail',
    }),
    doSubmit() {
      const loginPath = '/WebResetPasswordStart.php';

      const data = new FormData();
      data.append('login', this.model.email);
      fetch(server + loginPath, {
        method: 'POST',
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            this.$router.push({ name: 'errorCreatePassword' });
          }
          if (json.status === 'ok') {
            this.$router.push({ name: 'checkInbox' });
          }
        });
    },
  },
};
</script>
<style>

.login-container{
@media (min-width: 768px) {
  .col-md-6{
    padding: 0 40px;
  }
  .first-col {
    border-right: 1px solid #E0E0E0;
  }

}}
</style>
